<template>
  <div class="clearfix">
    <div class="container my-5" >
      <div class="row" style="text-align: justify;">
        <!-- /.col-lg-4 -->
        <div class="col-md-4">
          <img
            src="/img/circles/quejas.jpg"
            class="img-thumbnail rounded-circle my-4 mx-auto d-block"
            width="250px"
            height="250px"
            >
            <p>
              <strong>Dirección:</strong>
              <br>
              Carrera 73C Bis # 38A – 35 Sur, Oficina 101
            </p>
            <br>
            <p>
              <strong>E-Mail:</strong>
              <br>
              comercial@medicert.com.co
            </p>
            <br>
            <p>
              <strong>Ciudad:</strong>
              <br>
              BOGOTA D.C.
            </p>
            <br>
            <p>
              <strong>Teléfono:</strong>
              <br>
              (+571) 805 35 77 Bogotá D.C.
            </p>
            <br>
            <p>
              <strong>Celular:</strong>
              <br>
              +57 315 246 9739
            </p>
            <br>
        </div>
        <div class="col-md-8">

          <h4 class="">
           Diligencie este formato y presione el botón enviar formulario para formular toda clase de quejas y apelaciones, las cuales serán atendidas dentro del tiempo legalmente establecido.
           </h4>

           <div class="text-center">
              <b-link href="/docs/P-DC-05_Procedimiento_quejas_apelaciones.pdf" target="_blank">
                <span class="text-danger "><font-awesome-icon icon="file-pdf" size="6x" /></span>
                <br>
                <span class="text-black-50" >Procedimiento Quejas y Apelaciones Medicert Ltda.</span>
              </b-link>
            </div>

            <b-form class="my-5">
              <b-form-group
                label="Nombre"
                label-for="Nombre"
                class="col-8"
              >
                <b-form-input
                  v-model="form.nombre"
                  :state="nombreOk"
                  class="form-control"
                  id="Nombre"
                  placeholder="Nombre"
                ></b-form-input>
                <span class="text-danger" v-if="errors.nombre">{{
                  errors.nombre[0]
                }}</span>
              </b-form-group>
              <br>

              <b-form-group
                label="E-mail"
                label-for="email"
                class="col-8"
              >
                <b-form-input
                  v-model="form.email"
                  :state="emailOk"
                  class="form-control"
                  id="email"
                  placeholder="email"
                ></b-form-input>
                <span class="text-danger" v-if="errors.email">{{
                  errors.email[0]
                }}</span>
              </b-form-group>
              <br>

              <b-form-group
                label="Celular/Teléfono"
                label-for="movil"
                class="col-8"
              >
                <b-form-input
                  v-model="form.movil"
                  :state="movilOk"
                  class="form-control"
                  id="movil"
                  placeholder="movil"
                ></b-form-input>
                <span class="text-danger" v-if="errors.movil">{{
                  errors.movil[0]
                }}</span>
              </b-form-group>
              <br>

              <b-form-group
                label="¿Su Pregunta o Duda?"
                label-for="texto"
                class="col-8"
              >
                <b-form-textarea
                  v-model="form.texto"
                  :state="textoOk"
                  class="form-control"
                  id="texto"
                  placeholder="texto"
                  rows="3"
                  max-rows="8"
                ></b-form-textarea>
                <span class="text-danger" v-if="errors.texto">{{
                  errors.movil[0]
                }}</span>
              </b-form-group>
              <br>

              <b-form-group
                label="IP"
                label-for="ip"
                class="col-8"
              >
                <b-form-input
                  v-model="form.ip"
                  :state="ipOk"
                  class="form-control"
                  id="ip"
                  placeholder="ip"
                  disabled
                ></b-form-input>
                <span class="text-danger" v-if="errors.ip">{{
                  errors.ip[0]
                }}</span>
              </b-form-group>
              <br>

              <vue-recaptcha
                sitekey="6LebINQaAAAAAFBTKKdSxdwzYNGMjgT8HTcjoYcG"
                @verify="onVerify"
                @expired="onExpired"
                @error="onError"
                >
                </vue-recaptcha>
                <br>
                <b-button v-if="showButton" @click="sendForm" title="Aceptar" variant="secondary">
                  <span class="mr-1">Aceptar</span>
                  <font-awesome-icon icon="arrow-right" class="ms-2"/>
                </b-button>
            </b-form>
            <transition name="fade" mode="out-in">
            <template v-if="mensajero.on">
              <b-alert  :variant="mensajero.tipo" show>
                <p>{{ mensajero.texto }}</p>
                <br>
                <b-button variant="secondary" @click="renew" >Intentar de nuevo</b-button>
              </b-alert>
            </template>
            </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Connection from '@/api/Connection.js'
import Csrf from '@/api/Csrf'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Quejas',
  components: {
    VueRecaptcha
  },
  data () {
    return {
      form: {
        nombre: null,
        email: null,
        movil: null,
        texto: null,
        ip: null
      },
      mensajero: { on: false, tipo: null, texto: null },
      showButton: false,
      errors: []
    }
  },
  mounted () {
    this.$store.commit('setActualPage', 'Quejas y Apelaciones')
    this.getIPFromAmazon()
  },

  methods: {
    async sendForm () {
      await Csrf.getCookie()
      try {
        const response = await Connection.post('api/quejas/', this.form)
        console.log(response.data)
        this.mensajero = { on: true, tipo: 'success', texto: response.data.message }
        this.form = { nombre: null, email: null, movil: null, texto: null, ip: null }
        this.errors = { nombre: null, email: null, movil: null, texto: null, ip: null }
        this.getIPFromAmazon()
        this.showButton = false
      } catch (error) {
        this.mensajero = { on: true, tipo: 'danger', texto: 'El sistema ha tenido un problema interno. Intente cerrar el navegador y vuelva ahacer la consulta.' }
        this.reset()
        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        }
      }
    },

    renew () {
      this.$router.push({ path: '/quejas' })
    },

    reset () {
      this.showButton = false
      this.mensajero = { on: false, tipo: null, texto: null }
      this.form = { nombre: null, email: null, movil: null, texto: null, ip: null }
      this.errors = { nombre: null, email: null, movil: null, texto: null, ip: null }
      this.getIPFromAmazon()
    },

    onVerify: function (response) {
      this.showButton = true
      console.log('Verify: ' + response)
    },

    onExpired: function () {
      this.showButton = false
      console.log('Expired')
    },

    onError: function (error) {
      this.showButton = false
      console.log(error)
    },

    async getIPFromAmazon () {
      const con = axios.create({
        baseURL: 'https://www.cloudflare.com'
      })
      try {
        const jData = []
        const response = await con.post('https://www.cloudflare.com/cdn-cgi/trace')
        const lineas = response.data.split('\n')
        lineas.forEach(linea => {
          const ab = linea.split('=')
          jData[ab[0]] = ab[1]
        })

        this.form.ip = jData.ip + ' ' + jData.loc
      } catch (error) {
        console.log(error)
      }
    }
  },

  computed: {
    nombreOk () {
      return this.errors.nombre ? true : null
    },
    emailOk () {
      return this.errors.email ? true : null
    },
    movilOk () {
      return this.errors.movil ? true : null
    },
    textoOk () {
      return this.errors.texto ? true : null
    },
    ipOk () {
      return this.errors.ip ? true : null
    }
  }
}
</script>

<style>

</style>
